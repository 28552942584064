@import 'styles/colors.module';
@import 'assets/styles/mixins';
@import 'assets/styles/variables.module';


.rounded-modal {
  $this: &;

  &__veil {
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: $modalVeil;
    z-index: 11;

    &.open {
      display: flex;
    }

    &.confirm {
      z-index: 12;

      #{$this}__container {
        width: 506px;
        background-color: $whitePurple;
      }

      #{$this}__title {
        text-align: center;
      }
    }
  }
  &__container {
    width: min-content;
    @include sсroll-style(5px, true);
    position: relative;
    background: $white;
    border-radius: 12px;
    max-height: 95vh;
    overflow-y: auto;
    overflow-x: hidden;

    &.withoutScroll {
      overflow: initial;
    }

    &.overflowHidden {
      overflow: hidden;
    }
  }

  &__title-wrapper {
    display: flex;
    column-gap: 8px;
    justify-content: space-between;
  }

  &__title {
    @include text-ellipsis(3);
    margin: 0 0 24px 0;
    font-size: 16px;
    font-family: $font-main-bold;
    text-transform: uppercase;
    color: $solid-dark-grey;
    word-break: break-all;
    white-space: pre-wrap;
  }

  &__close-icon {
    @include clickable-element;
    position: absolute;
    font-size: 24px;
    top: 21px;
    right: 21px;

    path {
      fill: $solid-pink;
    }
  }
}
