@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';


.creative-dashboard {

  // Text area and input
  &__textarea,
  &__input {
    overflow: hidden;
    width: 100%;
    outline: none;
    color: $darkGrey;
    font-size: 10px;
    font-family: $font-main-bold;
    text-transform: uppercase;
    padding: 6px;
    background: $white;
    border: 1px solid $solid-light-grey;
    word-break: break-word;
    line-height: 11px;

    &--header {
      min-height: 100%;
    }

    &::placeholder {
      color: $placeholder-color;
    }

    &:focus {
      border: 1px solid $solid-blue;
    }

    &.error {
      border: 1px solid $solid-errors;
    }
  }

  &__textarea {
    resize: none;
  }

  &__input {
    height: 24px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__disabled-input {
    width: 100%;
    color: $darkGrey;
    font-size: 10px;
    font-family: $font-main-bold;
    text-transform: uppercase;
    padding: 6px 7px;
    background: $solid-light-grey;
    word-break: break-word;
    white-space: pre-wrap;
    line-height: 11px;
  }

  // Export screen
  &__export-screen {
    @include flex-centering;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $modalVeil;
    z-index: 10;
  }

  &__export-screen-content {
    width: 300px;
    height: 150px;
    background: $white;
    position: relative;

    &:before,
    &:after, {
      content: '';
      position: absolute;
      display: block;
      height: 5px;
      width: 100%;
    }

    &:before {
      top: 0;
      background: $blue-pink-gradient;
    }

    &:after {
      bottom: 0;
      background: $pink-blue-gradient;
      transform: scaleX(-1);
    }
  }
}