@import 'styles/colors.module';
@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';


.entity-grid-info {
  padding: 15px 16px 16px;
  letter-spacing: 0.3px;

  &__text {
    margin: 0;
    text-transform: uppercase;
  }

  &__header {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 8px;
  }

  &__title {
    @include text-primary;
  }

  &__subtitle {
    @include text-ellipsis;
    margin-top: 4px;
    font-size: 10px;
    font-family: $font-main-semiBold;
    color: $solid-dark-grey;
  }

  &__footer {
    margin-top: 6px;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
  }

  &__footer-left-info,
  &__footer-right-info {
    @include text-ellipsis;
    max-width: 90px;
    font-size: 12px;
    font-family: $font-main-thin;
    color: $solid-dark-grey;
  }

  &__footer-left-info {
    font-family: $font-main-semiBold;
    letter-spacing: 0.5px;
  }
}

