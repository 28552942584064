@import 'styles/colors.module';
@import 'assets/styles/mixins';
@import 'assets/styles/variables.module';


.asset-details-preview {
  $this: &;
  position: relative;
  display: flex;
  flex-direction: column;

  .preloader-container {
    position: absolute;
    inset: 0;
    background-color: $white;
    z-index: 5;
  }

  &__header {
    margin-bottom: 24px;

    &.editState {
      margin-bottom: 17px;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 656px 328px;
    grid-template-rows: 392px;
    grid-column-gap: 16px;
  }

  &__footer {
    margin-top: 32px;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 40px;
    &.hidden {
      display: none;
    }
  }

  &__name,
  &__name-textarea {
    margin: 0;
    padding: 0;
    font-size: 24px;
    line-height: 24px;
    font-family: $font-main-bold;
    text-transform: uppercase;
    color: $solid-dark-grey;
    word-break: break-all;
    white-space: pre-wrap;
  }

  &__name {
    @include text-ellipsis(3);
    border: 1px solid transparent;
  }

  &__name-textarea {
    @include sсroll-style(5px, true);
    width: 100%;
    background: $white;
    border: 1px solid $extra-light-grey;
    outline: none;
    resize: none;

    &:focus {
      border: 1px solid $pink;
    }

    &.error {
      border: 1px solid $error;
    }
  }

  &__name-error {
    margin: 0;
    padding: 4px 16px;
    font-size: 10px;
    font-family: $font-main-regular;
    color: $error;
  }

  &__media {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &.audio {
      display: grid;
      justify-content: initial;
      justify-items: center;
      grid-template-rows: 1fr auto;
    }
  }

  &__media-status-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 4;
    background: $white;
  }

  &__image {
    @include fit-image(contain);
  }

  &__info {
    background-color: $whitePurple;
    border-radius: 8px;
  }

  &__info-title {
    @include text-primary(14px);
    padding: 16px;
    border-bottom: 1px solid $white;
  }

  &__info-body {
    margin: 8px 3px 8px 0;
    height: 328px;
    @include sсroll-style(5px, true);
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__buttons-container {
    display: flex;
    justify-content: end;
    align-items: center;
    column-gap: 16px;
  }

  &__button {
    @include oval-button-primary(false, 130px, 40px, true);

    &.hidden {
      display: none;
    }

    &.inverse {
      @include oval-button-primary(true, 130px, 40px, true);
    }
  }

  &__switch-asset-button {
    @include clickable-element;
    @include flex-centering;
    width: 32px;
    height: 100%;
    top: 0;
    position: absolute;
    overflow: hidden;

    &.prev {
      left: -106px;
    }

    &.next {
      right: -106px;
    }

    &:disabled {
      pointer-events: none;

      #{$this}__switch-asset-icon.MuiSvgIcon-root {
        color: $solid-dark-grey;
      }
    }
  }

  &__switch-asset-icon.MuiSvgIcon-root {
    color: $white;
    font-size: 51px;
  }
}
