@import 'assets/styles/variables.module';
@import 'assets/styles/mixins';
@import 'styles/colors.module';

.automated-uploads {
  padding: 24px;
  &__games {
    width: 300px;
    border: 1px solid $lightGrey;
  }
  &__search {
    border-radius: 32px;
  }
  &__filters {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  &__select {
    padding: 0 16px !important;
    border: 1px solid $lightGrey;
    border-radius: 32px;
    font-size: 12px !important;
    width: 200px;
    height: 40px;
    text-transform: uppercase;
    div {
      &:focus {
        background-color: $white !important;
      }
    }
    &__empty {
      font-weight: bold !important;
      color: $solid-grey;
    }
  }
  &__menu-item {
    font-size: 12px !important;
    height: 18px;
  }
  &__no-games {
    padding: 16px;
    font-size: 16px;
    line-height: 24px;
    color: $solid-dark-grey;
  }
  &__not-selected {
    margin: 24px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    &-text {
      font-size: 24px;
      line-height: 40px;
    }
  }
  &__divider {
    width: 100%;
    border-top: 1px solid $lightGrey;
    margin: 24px 0;
  }
  &__button {
    @include rectangle-button(true, 153px);
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    &::before {
      border-radius: 24px;
    }
  }
  &__game {
    display: flex;
    align-items: center;
    color: #59595C;
    line-height: 1.75;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 1px solid $lightGrey;
    height: 60px;
    padding: 10px;
    gap: 16px;
    &.selected {
      background-color: $lightBlue;
    }
    &:hover {
      background-color: $blue;
      color: $white;
      cursor: pointer;
    }
    &-image {
      height: 40px;
      width: 40px !important;
    }
    &-title {
      width: 220px;
      overflow: hidden;
      white-space: nowrap;
      text-transform: uppercase;
      text-overflow: ellipsis;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
  }
  &__settings {
    flex: 1;
    display: flex;
    flex-direction: row;
    background-color: $lightGrey;
    margin-left: 24px;
    padding: 18px;
    gap: 36px;
    height: 300px;
    img {
      width: 264px;
      height: 264px;
    }
  }
}

.game-settings {
  &__edit-button {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 1.2em;
    background-color: $lightBlue;
    color: $blue;
    padding: 8px 16px;
    font-weight: bold;
    .edit-icon {
      width: 1.2em;
      height: 1.2em;
      path {
        fill: $blue;
      }
    }
    &:hover {
      cursor: pointer;
      background-color: $blue;
      color: $white;
      .edit-icon {
        path {
          fill: $white;
        }
      }    
  
    }
    &:disabled {
      cursor: not-allowed;
      background-color: $solid-grey;
      color: $solid-dark-grey;
      .edit-icon {
        path {
          fill: $solid-dark-grey;
        }
      }
      :hover {}
    }
  }
  &__data {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;
    &-row {
      display: flex;
      flex-direction: row;
      &__value {
        font-weight: bold;
        margin-left: 8px;
      }
      &__tags {
        display: flex;
        gap: 8px;
        margin-left: 12px;
      }
      &__tag {
        background-color: $white;
        text-transform: uppercase;
        padding: 0 8px;
        height: 24px;
        line-height: 24px;
      }
    }
  }
}

.small-loader {
  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.plus-icon {
  width: 1.3em;
  height: 1.3em;
  path {
    fill: $white;
  }
}