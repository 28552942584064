@import 'assets/styles/variables.module';
@import 'styles/colors.module';
@import 'assets/styles/mixins';


.search-box {
  $this: &;
  position: relative;

  &__trigger {
    @include flex-centering;
    height: 40px;
    gap: 5px;
    padding: 0 16px;
    border: 1px solid $solid-light-grey;

    &:focus-within {
      border: 1px solid $solid-blue;
    }

    &.rounded {
      border-radius: 32px;
    }
  }

  &__input {
    width: 100%;
    height: 30px;
    outline: none;
    border: none;
    font-family: $font-main-bold;
    font-size: 12px;
    color: $darkGrey;
    text-transform: uppercase;

    &::placeholder {
      color: $placeholder-color;
    }
  }

  &__icon {
    font-size: 18px;
    color: $solid-blue;

    &--clear {
      font-size: 16px;
      cursor: pointer;
    }
  }

  &__dropDown {
    @include sсroll-style(4px);
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 240px;
    border-left: 1px solid $solid-light-grey;
    border-right: 1px solid $solid-light-grey;
    border-bottom: 1px solid $solid-light-grey;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  }

  &__suggestion {
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 14px 16px;
    background: $white;
    cursor: pointer;

    &:hover #{$this}__suggestion-text {
      color: $solid-blue;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $solid-light-grey;
    }
  }

  &__suggestion-text {
    @include text-ellipsis(3);
    overflow: hidden;
    margin: 0;
    color: $darkGrey;
    font-family: $font-main-bold;
    font-size: 10px;
    text-transform: uppercase;
  }
}